import React from 'react'
import Accordion from '../components/Accordion'
import { getCoursesById } from '../features/getCourses';
import { useState , useEffect} from 'react';
import { useParams } from 'react-router-dom';
import './CourseDetail.css'
import { useCookies } from "react-cookie";
import { BrowserRouter as Router, Route, Link } from "react-router-dom"



function CourseDetail() {
  const {id}= useParams()
  const [course, setCourse] = useState([])

  const [cookies, setCookie, removeCookie] = useCookies(null);
  const authToken = cookies.AuthToken;

  const [user, setUser] = useState({
    Id: '',
    Name: '',
    Email: '',
    Number: '',
    Role: '',
    CoursePermission: ''
  });

  useEffect(()=>{
    getCoursesById(id).then((res) =>{
      setCourse(res);
      console.log(res)
    })
  },[])

  const getUser = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVERURL}/api/users/me`, {
        method: 'GET',
        headers: 
        { 
          'Authorization' : 'Bearer ' + authToken,
          'Content-Type' : 'application/json'
        },
      })
      
      const userData = await response.json()

      setUser( user => 
        ({ ...user,
        Id: userData.id,
        Email: userData.email,
        Name: userData.name,
        Number: userData.number,
        Role: userData.role,
        CoursePermission: userData.course
      }));

    } catch(err) {
      console.error(err)
    }
    
  };

  useEffect( () => {
    if (authToken) {
      getUser()
    }
  }, [authToken]);


  return (
    <>
    <div className="bg-gray-900  text-gray-200 ">
    <div className="container px-5 py-16 mx-auto flex flex-wrap items-center ">
            <div className="lg:w-5/12 md:w-1/2 md:pr-16 lg:pr-0 pr-0">
                <h1 className="title-font font-medium text-5xl text-gray-100">{course.course_name}</h1>
                <h3 className="title-font font-medium text-xl text-gray-100 mb-10">by <span className='text-orange-400'>{course.author}</span></h3>
                <div className="leading-relaxed mt-4 course-details">{course.course_details}</div>
            </div>
            <div class="lg:w-5/12 md:w-1/2 bg-gray-100 rounded-lg p-8 flex flex-col md:ml-auto w-fit mt-10 md:mt-0">
                <img class="rounded w-full object-cover object-center mb-6" src={course.course_img} alt="content" />
                <Link class="flex-shrink-0 text-white font-medium  bg-blue-600 border-0 py-2 px-8 focus:outline-none hover:bg-blue-800 rounded-lg text-2xl w-full text-center"
                  to={user.CoursePermission.includes(course.course_order) ? '#' : '/buy-course'}
                  state={{
                    courseName: course.course_name,
                    coursePrice: course.price,
                    courseImg: course.course_img,
                    user: user
                  }}
                  >
                  {user.CoursePermission.includes(course.course_order) ? 'BẠN ĐÃ SỞ HỮU KHOÁ HỌC' : 'ĐĂNG KÝ NGAY'}
                </Link>
                <p class="inline-block p-3 mr-1 my-1 rounded-md text-orange-600 font-bold text-xl tracking-widest">Giá khoá học: {course.price}</p>
            </div>
    </div>    
    </div>
    <div className="container px-5 py-16 mx-auto flex flex-wrap items-center ">
    <h1 className="title-font font-medium text-3xl pb-16">Nội dung khoá học</h1>
        {course.sections && user.CoursePermission.includes(course.course_order) ? <Accordion items={course.sections}  />  : <p class="w-full text-xl font-semibold text-red-600"> Bạn chưa sở hữu khoá học này</p> }
    </div>
    </>
  )
}

export default CourseDetail