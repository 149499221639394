import React from 'react'
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Link } from 'react-router-dom';
import AddCourse from '../components/AddCourse';
import UserManagement from '../components/UserManagement';

function User() {
  const [createCourse, setCreateCourse] = useState(false)
  const [cookies, setCookie, removeCookie] = useCookies(null);
  const authToken = cookies.AuthToken;
  const [user, setUser] = useState({
    Id: '',
    Name: '',
    Email: '',
    Number: '',
    Role: ''
  });

  const getUser = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVERURL}/api/users/me`, {
        method: 'GET',
        headers: 
        { 
          'Authorization' : 'Bearer ' + authToken,
          'Content-Type' : 'application/json'
        },
      })
      
      const userData = await response.json()

      setUser( user => 
        ({ ...user,
        Id: userData.id,
        Name: userData.name,
        Email: userData.email,
        Number: userData.number,
        Role: userData.role,
        Course: userData.course
      }));

    } catch(err) {
      console.error(err)
    }
    
  };

  useEffect( () => {
    if (authToken) {
      getUser()
    }
  }, [authToken]);

  return (
    <div class="grid grid-cols-1 gap-4">
      <section class="text-gray-600 body-font">      
        <div class="container px-5 py-24 mx-auto flex flex-col items-center">
          <div class="lg:w-4/6 md:w-1/2 bg-gray-200 rounded-lg p-8 flex flex-col md:m-auto w-full mt-10 md:mt-0 md:mb-8">
            <h2 class="text-gray-900 text-lg font-medium title-font mb-5">Thông tin tài khoản</h2>
              { user.Role === 'admin' || user.Role === "superadmin"  ? 
                <div class="relative mb-8">
                  <p><span className='font-semibold bg-blue-500 p-2 text-white rounded-lg'>Tài khoản quản trị</span></p>
                </div>
                :
                <div class="relative mb-8">
                  <p><span className='font-semibold bg-blue-500 p-2 text-white rounded-lg'>Tài khoản người dùng</span></p>
                </div>
              }

              <div class="relative mb-4">
                <p><span className='font-semibold'>ID Tài Khoản: </span>{user.Id}</p>
              </div>
              <div class="relative mb-4">
                <p><span className='font-semibold'>Tên của bạn: </span>{user.Name}</p>
              </div>
              <div class="relative mb-4">
                <p><span className='font-semibold'>Địa chỉ Email: </span>{user.Email}</p>
              </div>
              <div class="relative mb-4">
                <p><span className='font-semibold'>Số điện thoại: </span>{user.Number}</p>
              </div>
          </div>
          {
            //user.Role === "superadmin" ?
             false ?
              <div class="lg:w-4/6 md:w-1/2 bg-gray-200 rounded-lg p-8 flex flex-col md:m-auto w-full mt-10 md:mt-0 md:mb-8">
                <h2 class="text-gray-900 text-lg font-medium title-font mb-5">Tất cả khoá học</h2>

                
                <div class="relative mb-4">
                </div>

                <div class="relative mb-4">
                  <button onClick={() => setCreateCourse(!createCourse)} class="inline-flex items-center text-white bg-blue-600 border-0 py-2 px-5 focus:outline-none hover:bg-blue-800 rounded-lg text-lg mt-4 md:mt-0">Tạo Mới</button>
                  
                  {/*Course Create */}

                  { createCourse ? 
                      <div>
                        <AddCourse />
                      </div>
                      : 
                      <></> }

                </div>

              </div>
            : 
            <div class="lg:w-4/6 md:w-1/2 bg-gray-200 rounded-lg p-8 flex flex-col md:m-auto w-full mt-10 md:mt-0 md:mb-8">
              <h2 class="text-gray-900 text-lg font-medium title-font mb-5">Khoá học của bạn</h2>

              <div class="relative mb-4 text-lg text-blue-600 font-medium">
                <p>Các khoá học của bạn: <span class='text-blue-800'>{ user.Course ? 'Khoá thứ tự số' : ''} {user.Course}</span></p>
              </div>
            </div> 
          }

          { user.Role === "admin" || user.Role === "superadmin" ?
            <div class="lg:w-4/6 md:w-1/2 bg-gray-200 rounded-lg p-8 flex flex-col md:m-auto w-full mt-10 md:mt-0">
                <h2 class="text-gray-900 text-lg font-medium title-font mb-8">Danh sách người dùng</h2>
                <div class="relative mb-4">
                  <UserManagement userToken={authToken} userRole={user.Role}/>
                </div>

            </div>
            :
            <>
            </> 
          }

        </div>
      </section>
    </div>
  )
}

export default User