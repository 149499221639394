import React from 'react'
import { Link } from "react-router-dom"


const Footer = () => {

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  }

  return (
    <footer className="text-gray-600 body-font">
    <div className="container px-5 py-24 mx-auto">
    <div className="flex flex-wrap md:text-left text-center order-first">
      <div className="lg:w-1/4 md:w-1/2 w-full px-4">
        <h2 className="title-font font-medium text-blue-800 tracking-widest text-base mb-3">SITEMAP</h2>
        <nav className="list-none mb-10">
          <li className='mb-3'>
            <Link onClick={scrollToTop} to='/'><a className="text-gray-800 hover:text-gray-800">Trang Chủ</a></Link>
          </li>
          <li className='mb-3'>
            <Link onClick={scrollToTop} to='/courses'><a className="text-gray-800 hover:text-gray-800">Khoá Học</a></Link>
          </li>
          <li className='mb-3'>
            <Link onClick={scrollToTop} to='/about'><a className="text-gray-800 hover:text-gray-800">Về Facemax</a></Link>
          </li>
          <li className='mb-3'>
            <Link onClick={scrollToTop} to='/contact'><a className="text-gray-800 hover:text-gray-800">Liên Hệ</a></Link>
          </li>
          <li>
            <Link onClick={scrollToTop} to='/login'><a className="text-gray-800 hover:text-gray-800">Đăng Nhập</a></Link>
          </li>
        </nav>
      </div>
      <div className="lg:w-1/4 md:w-1/2 w-full px-4">
        <h2 className="title-font font-medium text-blue-800 tracking-widest text-base mb-3">THÔNG TIN LIÊN HỆ</h2>
        <nav className="list-none mb-10">
          <li className='mb-3'>
            <a href="tel:19003119" className="text-gray-800 hover:text-gray-800"><span className='font-semibold'>Hotline:</span> 1900 3119</a>
          </li>
          <li className='mb-3'>
            <a href="mailto:hoidapdalieu@gmail.com" className="text-gray-800 hover:text-gray-800"><span className='font-semibold'>Email:</span> hoidapdalieu@gmail.com</a>
          </li>
          <li className='mb-3'>
            <a className="text-gray-800 hover:text-gray-800"><span className='font-semibold'>Hệ thống Website:</span>
            <br />facemax.vn
            <br />khoahoctrinam.com
            </a>
          </li>
        </nav>
      </div>
      <div className="lg:w-1/4 md:w-1/2 w-full px-4">
        <h2 className="title-font font-medium text-blue-800 tracking-widest text-base mb-3">MẠNG XÃ HỘI</h2>
        <nav className="list-none mb-10">
          <li className='mb-3'>
            <a className="text-gray-800 hover:text-gray-800" href="https://www.youtube.com/@FaceMaxCosmetics" target="_blank" rel="noreferrer noopener">Youtube</a>
          </li>
          <li className='mb-3'>
            <a className="text-gray-800 hover:text-gray-800" href="https://m.me/facemax.hoidap" target="_blank" rel="noreferrer noopener">Facebook</a>
          </li>
          <li className='mb-3'>
            <a className="text-gray-800 hover:text-gray-800" href="https://zalo.me/0985969328" target="_blank" rel="noreferrer noopener">Zalo</a>
          </li>
        </nav>
      </div>
      <div className="lg:w-1/4 md:w-1/2 w-full px-4">
        <h2 className="title-font font-medium text-blue-800 tracking-widest text-base mb-3">ĐỊA CHỈ</h2>
        <nav className="list-none mb-10">
          <li>
            <p className="text-gray-800 hover:text-gray-800 mb-3"><span className='font-medium'>Miền Bắc:</span> Số 1 Trần Quốc Vượng, Q. Cầu Giấy, Hà Nội.</p>
          </li>
          <li>
            <p className="text-gray-800 hover:text-gray-800 mb-3"><span className='font-medium'>Miền Trung:</span> 391 Lê Duẩn, P. Tân Chính, Quận Thanh Khê, Đà Nẵng.</p>
          </li>
          <li>
            <a className="text-gray-800 hover:text-gray-800"><span className='font-medium'>Miền Nam:</span> Số 129 Đinh Tiên Hoàng, Quận 1, TP. Hồ Chí Minh.</a>
          </li>
        </nav>
      </div>
    </div>
  </div>
  <div className="bg-gray-100">
    <div className="container px-5 py-6 mx-auto flex items-center sm:flex-row flex-col">
      <a className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
        <img src='/img/facemax-logo-height-512-black.png' class="" width='150'/>
      </a>
      <p className="text-sm text-gray-500 sm:ml-6 sm:mt-0 mt-4">© 2023 FacemaxTV —
        <a href="" rel="noopener noreferrer" className="text-gray-600 ml-1" target="_blank">by LW Software</a>
      </p>
      <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
        <a className="text-gray-500">
        </a>
        <a className="ml-3 text-gray-500">
        </a>
        <a className="ml-3 text-gray-500">
        </a>
        <a className="ml-3 text-gray-500">
        </a>
      </span>
    </div>
  </div>
</footer>

  )
}

export default Footer