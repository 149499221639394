import React, { useState } from 'react'
import { useCookies } from 'react-cookie'

const Register = () => {
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [number, setNumber] = useState(null);
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [error, setError] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies(null);
  
  function validateEmail(email) {
    var regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return regex.test(email);
  }

  const handleSubmit = async (e, endpoint) => {
    e.preventDefault()
    
    if (!name|| !email || !number || !password || !confirmPassword) {
      setError('Vui lòng nhập đủ các thông tin!')
      return
    } else {
      setError(null)
      if(!validateEmail(email)) {
        setError('Email không hợp lệ')
        return
      } else {
        setError(null)
        if (password.length < 8) {
          setError('Vui lòng nhập mật khẩu từ 8 đến 20 ký tự!')
          return
        } else {
          setError(null)
          if (password !== confirmPassword) {
            setError('Mật khẩu không khớp!')
            return
          } else {
            setError(null)
          }
        }
      }
    }

    const response = await fetch(`${process.env.REACT_APP_SERVERURL}/${endpoint}`, {
      method: 'POST',
      headers: { 'Content-Type' : 'application/json' },
      body: JSON.stringify({name, email, number, password})
    })

    const data = await response.json()
    console.log(data)
    if (data.detail) {
      setError(data.detail)
    }

  }

  return (
    <section class="text-gray-600 body-font">      
      <div class="container px-5 py-24 mx-auto flex flex-wrap items-center">
        <div class="lg:w-2/6 md:w-1/2 bg-gray-200 rounded-lg p-8 flex flex-col md:m-auto w-full mt-10 md:mt-0">
          <h2 class="text-gray-900 text-lg font-medium title-font mb-5">Đăng ký</h2>
          <form id="regForm">

            <div class="relative mb-4">
              <label for="name" class="leading-7 text-base text-gray-700">Tên của bạn</label>
              <input type="text" id="name" name="name" class="w-full bg-white rounded-sm border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-300 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                onChange={(e) => setName(e.target.value)}/>
            </div>

            <div class="relative mb-4">
              <label for="email" class="leading-7 text-base text-gray-700">Địa chỉ Email</label>
              <input type="email" id="email" name="email" class="w-full bg-white rounded-sm border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-300 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"  
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                onChange={(e) => setEmail(e.target.value)}/>
            </div>

            <div class="relative mb-4">
              <label for="tel" class="leading-7 text-base text-gray-700">Số điện thoại</label>
              <input type="tel" id="tel" name="tel" class="w-full bg-white rounded-sm border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-300 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              maxLength="10"
              onChange={(e) => setNumber(e.target.value)}/>
            </div>

            <div class="relative mb-4">
              <label for="password" class="leading-7 text-base text-gray-700">Mật khẩu (8-20 ký tự)</label>
              <input type="password" id="password" name="password" class="w-full bg-white rounded-sm border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-300 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              minLength="8" maxlength="20"
              onChange={(e) => setPassword(e.target.value)}/>
            </div>

            <div class="relative mb-4">
              <label for="password2" class="leading-7 text-base text-gray-700">Nhập lại mật khẩu (8-20 ký tự)</label>
              <input type="password" id="password2" name="password2" class="w-full bg-white rounded-sm border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-300 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              minLength="8" maxlength="20"
              onChange={(e) => setConfirmPassword(e.target.value)}/>
            </div>

            <input type="submit" value="Đăng Ký" class="text-white bg-blue-600 border-0 py-2 px-8 focus:outline-none hover:bg-blue-800 rounded-lg text-lg w-full"
            onClick={(e) => handleSubmit(e, 'api/users/register')}/>  
            {error && <p style={{color: 'red', fontSize: '1.125rem', marginTop: '8px'}}>{error}</p>}

          </form>
        </div>
      </div>
    </section>
  )
}

export default Register