import React from 'react'
import {Link } from 'react-router-dom';

const CourseList = ({data}) => {
  return (
    <Link to={`/courses/${data._id}`}>
        <div className="px-2 py-4 border-b-2 border-gray-300 flex flex-wrap md:flex-nowrap ">
            <div className="md:w-64 md:mb-0 w-full pr-6 flex-shrink-0 flex flex-col">
              <img class="rounded w-full object-cover object-center mb-6" src={data.course_img} alt="content" />
            </div>
            <div className="md:flex-grow">
            <h2 className="text-2xl font-bold text-blue-800 title-font mb-2">{data.course_name}</h2>
            <h3 class="tracking-widest text-blue-600 text-base font-medium title-font mb-4">{data.author}</h3>
            <p className="leading-relaxed text-gray-900">{data.course_descriptions}</p>
            <div className="my-2">
                <h3 class="tracking-widest text-gray-500 text-xs font-medium title-font">
                    <span class="inline-block p-2  mr-1 my-1 rounded-md bg-gray-200 text-orange-600 font-bold text-base tracking-widest">{data.price}</span>
                    <span class="inline-block p-1  mr-1 my-1 rounded-md bg-gray-100 font-medium tracking-widest">{data.category}</span>
                </h3>
            </div>
            </div>
        </div>
    </Link>
  )
}

export default CourseList