import React from 'react'

const AboutUs = () => {
  return (
    <section className="text-gray-900 body-font">
        <div className="px-5 py-24 mx-auto flex flex-col">
            <div className="lg:w-4/6 mx-auto">
            <div className="rounded-lg h-64 overflow-hidden">
                <img alt="content" className="object-cover object-center h-full w-full" src="/img/1200x500.jpg" />
            </div>
            <div className="flex flex-col sm:flex-row mt-10">
                <div className="sm:w-1/3 text-center sm:pr-8 sm:py-8">
                <div className="w-20 h-20 rounded-full inline-flex items-center justify-center bg-gray-200 text-gray-400">
                    <img src='/img/thanh-nguyen-500x500.jpg' className='rounded-full' />
                </div>
                <div className="flex flex-col items-center text-center justify-center">
                    <h2 className="font-medium title-font mt-4 text-gray-900 text-lg">Master Thành Nguyễn</h2>
                    <div className="w-12 h-1 bg-green-500 rounded mt-2 mb-4"></div>
                    <p className="text-base">Chuyên gia trong lĩnh vực làm đẹp và trị nám da hàng đầu Việt Nam với hơn 10 năm kinh nghiệm.</p>
                </div>
                </div>
                <div className="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
                <p className="leading-relaxed text-lg mb-4">Facemax là thương hiệu mỹ phẩm hàng đầu của Việt Nam Với mong muốn không chỉ mang đến cộng đồng những sản phẩm chất lượng đạt chuẩn , bên cạnh đó chúng tôi còn muốn mang đến một giá trị về mặt kiến thức và kinh nghiệm thực tiễn của các chuyên gia cũng như những người có chuyên môn trong ngành thẩm mỹ.</p>
                </div>
            </div>
            </div>
        </div>
    </section>
  )
}

export default AboutUs