import React, { useState } from 'react'
import { useCookies } from 'react-cookie'

const Login = () => {
  const [isLogin, setIsLogin] = useState(true)
  const [email, setEmail] = useState(null)
  const [password, setPassword] = useState(null)
  const [error, setError] = useState(null)
  const [cookies, setCookie, removeCookie] = useCookies(null)

  const viewLogin = (status) => {
    setError(null)
    setIsLogin(status)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const response = await fetch(`${process.env.REACT_APP_SERVERURL}/api/users/login`, {
      method: 'POST',
      headers: { 'Content-Type' : 'application/json' },
      body: JSON.stringify({ email, password })
    })

    const data = await response.json()
    
    if (data.detail) {
      setError(data.detail)
      return
      
    } else {
      setCookie('Email', data.email)
      setCookie('Name', data.name)
      setCookie('AuthToken', data.token)
      window.location.href = `${process.env.REACT_APP_LOCALHOST}`;
    }
  }

  return (
    <section className="text-gray-600 body-font">      
      <div className="container px-5 py-24 mx-auto flex flex-wrap items-center">
        <div className="lg:w-2/6 md:w-1/2 bg-gray-200 rounded-lg p-8 flex flex-col md:m-auto w-full mt-10 md:mt-0">
          <h2 className="text-gray-900 text-lg font-medium title-font mb-5">Đăng nhập</h2>
          <form>

            <div className="relative mb-4">
              <label for="email" className="leading-7 text-base text-gray-700">Địa chỉ Email</label>
              <input type="email" id="email" name="email" className="w-full bg-white rounded-sm border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              onChange={(e) => setEmail(e.target.value)}/>
            </div>

            <div className="relative mb-4">
              <label for="full-name" className="leading-7 text-base text-gray-700">Mật khẩu</label>
              <input type="password" id="password" name="password" className="w-full bg-white rounded-sm border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              onChange={(e) => setPassword(e.target.value)}/>
            </div>

            <input type="submit" value="Đăng Nhập" class="text-white bg-blue-600 border-0 py-2 px-8 focus:outline-none hover:bg-blue-800 rounded-lg text-lg w-full"
            onClick={(e) => handleSubmit(e)}/>
            {error && <p style={{color: 'red', fontSize: '1.125rem', marginTop: '8px'}}>{error}</p>}

          </form>
          {/*<p className="text-s text-gray-600 mt-4 hover:text-blue-600">Bạn quên mật khẩu?</p>*/}
        </div>
      </div>
    </section>
  )
}

export default Login