import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import  { Redirect } from 'react-router-dom'

const Header = () => {
  const [cookies, setCookie, removeCookie] = useCookies(null)
  const [loggined, setLoginned] = useState(cookies.Name)

  const signOut = () => {
    removeCookie('Email')
    removeCookie('AuthToken')
    removeCookie('Name')
    
    window.location.href = `${process.env.REACT_APP_LOCALHOST}`;

  }


  return (
  <header class= "text-gray-700 body-font font-semibold shadow-md">
    <div class=" container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center justify-center">
      <Link class="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0" to="/">
        <img src='/img/facemax-logo-height-512-black.png' class="" width='200'/>
      </Link>
      <nav class="md:m-auto flex flex-wrap items-center text-lg justify-center">
        <Link class="mr-8 hover:text-blue-600" to="/courses">Khoá Học</Link>
        <Link class="mr-8 hover:text-blue-600" to="/about">Về Facemax</Link>
        <Link class="mr-8 hover:text-blue-600" to="/contact">Liên Hệ</Link>
      </nav>
      { loggined ? 
        <div className='flex flex-row items-center justify-center'>
        <Link to={`user-dashboard`}><button class="inline-flex items-center text-white bg-blue-600 border-0 py-2 px-5 focus:outline-none hover:bg-blue-800 rounded-lg text-lg mt-4 md:mt-0">
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1" x="0px" y="0px" width="28px" height="28px" viewBox="0 0 45.532 45.532" fill='#ffffff'>
              <path d="M22.766,0.001C10.194,0.001,0,10.193,0,22.766s10.193,22.765,22.766,22.765c12.574,0,22.766-10.192,22.766-22.765   S35.34,0.001,22.766,0.001z M22.766,6.808c4.16,0,7.531,3.372,7.531,7.53c0,4.159-3.371,7.53-7.531,7.53   c-4.158,0-7.529-3.371-7.529-7.53C15.237,10.18,18.608,6.808,22.766,6.808z M22.761,39.579c-4.149,0-7.949-1.511-10.88-4.012   c-0.714-0.609-1.126-1.502-1.126-2.439c0-4.217,3.413-7.592,7.631-7.592h8.762c4.219,0,7.619,3.375,7.619,7.592   c0,0.938-0.41,1.829-1.125,2.438C30.712,38.068,26.911,39.579,22.761,39.579z"/>
            </svg>
           <span className='ml-3'>Xin chào: {cookies.Name}</span></button></Link>
        <Link to={``}><button class="ml-3 inline-flex items-center bg-gray-100 border-0  py-2 px-5 focus:outline-none hover:bg-gray-300 rounded-lg text-lg mt-4 md:mt-0" onClick={signOut}>Đăng Xuất</button></Link>
        </div> 
      :   
        <div className='flex flex-row'>
          <Link to='register'><button class="inline-flex items-center text-white bg-blue-600 border-0 py-2 px-5 focus:outline-none hover:bg-blue-800 rounded-lg text-lg mt-4 md:mt-0">Đăng Ký</button></Link>
          <Link to={`login`}><button class="ml-3 inline-flex items-center bg-gray-100 border-0  py-2 px-5 focus:outline-none hover:bg-gray-300 rounded-lg text-lg mt-4 md:mt-0">Đăng Nhập</button></Link>
        </div>
      }

    </div>
  </header>
  )
}

export default Header