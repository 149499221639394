import React from 'react'
import {Link } from 'react-router-dom';

const Card = ({data}) => {
  return (
        <Link to={`/courses/${data._id}`} class='min-h-full'>
          <div class="p-5 mx-3 min-h-full bg-gray-200 rounded-lg" >
              <div>
                  <img class="rounded w-full object-cover object-center mb-6" src={data.course_img} alt="content" />
                  <h3 class="tracking-widest text-blue-600 text-base font-medium title-font mb-3">{data.author}</h3>
                  <h2 class="text-2xl text-blue-800 font-bold title-font mb-3">{data.course_name}</h2>
                  <h3 class="tracking-widest text-orange-600 text-lg font-bold title-font mb-3">{data.price}</h3>
                  <p class="leading-relaxed text-base text-gray-900">{data.course_descriptions}</p>

              </div>
          </div>
        </Link>
  )
}

export default Card