import React from 'react'

const Hero = () => {
  return (
    <section className="text-gray-600 body-font">
        <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1 className="title-font sm:text-5xl text-4xl mb-4 font-bold text-blue-800">KHOÁ HỌC DA LIỄU
            <br className="hidden lg:inline-block" />TỪ CHUYÊN GIA HÀNG ĐẦU
            </h1>
            <p className="mb-8 leading-relaxed text-lg text-gray-800 font-semibold">Chúng tôi luôn mong muốn mang đến những sản phẩm chất lượng, những giá trị về mặt kiến thức và kinh nghiệm từ các chuyên gia có chuyên môn cao trong ngành thẩm mỹ.</p>
            <div className="flex justify-center">
            <a href="register"><button className="rounded-lg inline-flex text-white bg-blue-600 border-0 py-2 px-6 focus:outline-none hover:bg-blue-800 text-lg">ĐĂNG KÝ</button></a>
            <a href="login"><button className="rounded-lg ml-4 inline-flex text-gray-700 bg-gray-200 border-0 py-2 px-6 focus:outline-none hover:bg-gray-400 text-lg">ĐĂNG NHẬP</button></a>
            </div>
        </div>
        <div className="lg:max-w-xl lg:w-full md:w-1/2 w-5/6">
            <img className="object-cover object-center rounded" alt="hero" src="/img/hero-img.jpg" />
        </div>
        </div>
    </section>
  )
}

export default Hero