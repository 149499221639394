import React, {useState} from 'react'
import { useForm } from "react-hook-form";

const Contact = () => {

    const [openModal, setOpenModal] = useState(false);

    const handleOpenModal = () => {
        setOpenModal(!openModal);
    }
    const handleCloseModal = () => {
        setOpenModal(!openModal);
    }

    const {
        register,
        handleSubmit,
        formState: { errors }
      } = useForm();

    const onSubmit = (data, e) => {
        e.preventDefault();
        const action = e.target.action;
        const form = document.getElementById('form-customer');

        const name = data.name
        const tel = data.tel
        const email = data.email
        const note = data.note

        const data1 = new FormData(form);
        data1.set("name", name)
        data1.set("tel", tel)
        data1.set("email", email)
        data1.set("note", note)

        fetch(action, {
            method: 'POST',
            body: data1
        })
    
        setTimeout(setOpenModal(true), 1000);
    };


  return (
    <section class="text-gray-800 body-font relative">
        <div class="px-5 py-24 mx-auto">
            <div class="flex flex-col text-center w-full mb-12">
            <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Liên hệ với FacemaxTV</h1>
            <p class="lg:w-2/3 mx-auto leading-relaxed text-lg">Hãy để lại lời nhắn và chúng tôi sẽ liên hệ trực tiếp với bạn.</p>
            </div>
            <div class="lg:w-1/2 md:w-2/3 mx-auto">
            <form id='form-customer' onSubmit={handleSubmit(onSubmit)} className="customer-information"
                 method='POST'
                 action='https://script.google.com/macros/s/AKfycbw5hIbLJwuBBykoCVdj8lkGnRE9nC2XLq9BK9-W70EXmKepBgvHNNzpBF-MW2HvbsxI1Q/exec'
            >
            <div class="flex flex-wrap m-2">
                <div class="p-2 w-1/2">
                <div class="relative">
                    <label for="name" class="leading-7 text-base text-gray-800">Tên của bạn</label>
                    <input type="text" id="name" name="name" class="w-full bg-gray-100 bg-opacity-50 rounded-sm border-2 border-gray-300 focus:border-blue-400 focus:bg-white focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" 
                    {...register("name", {
                        required: true
                    })}/>
                    {errors.name && <p class='text-red-600'>Vui lòng nhập tên.</p>}
                </div>
                </div>
                <div class="p-2 w-1/2">
                <div class="relative">
                    <label for="tel" class="leading-7 text-base text-gray-800">Số điện thoại</label>
                    <input type="tel" id="tel" name="tel" maxLength="10" class="w-full bg-gray-100 bg-opacity-50 rounded-sm border-2 border-gray-300 focus:border-blue-400 focus:bg-white focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    {...register("tel", {
                    required: true,
                    pattern: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
                    })}/>
                    {errors.tel && <p class='text-red-600'>Vui lòng nhập SĐT.</p>}
                </div>
                </div>
                <div class="p-2 w-full">
                <div class="relative">
                    <label for="email" class="leading-7 text-base text-gray-800">Địa chỉ Email (tuỳ chọn)</label>
                    <input type="email" id="email" name="email" class="w-full bg-gray-100 bg-opacity-50 rounded-sm border-2 border-gray-300 focus:border-blue-400 focus:bg-white focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    {...register("email", {
                        required: true,
                        pattern: /^\S+@\S+$/i
                    })}/>
                    {errors.email && <p class='text-red-600'>Vui lòng nhập Email.</p>}
                </div>
                </div>
                <div class="p-2 w-full">
                <div class="relative">
                    <label for="message" class="leading-7 text-base text-gray-800">Lời nhắn</label>
                    <textarea id="message" name="message" class="w-full bg-gray-100 bg-opacity-50 rounded-sm border-2 border-gray-300 focus:border-blue-400 focus:bg-white focus:ring-2 focus:ring-blue-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                    {...register("note")} />
                </div>
                </div>
                <div class="p-2 w-full flex flex-col items-center justify-center">
                <button type='submit' class="flex mx-auto text-white bg-blue-600 border-0 py-2 px-8 focus:outline-none hover:bg-blue-800 rounded-lg text-lg">
                    LIÊN HỆ
                </button>
                {openModal && <p class='text-blue-800 mt-5 font-semibold text-xl'>Đã gửi yêu cầu!</p>}
                </div>
                <div class="p-2 w-full pt-8 mt-8 border-t border-gray-200 text-center">
                <a class="text-blue-800">Email: hoidapdalieu@gmail.com</a>
                <p class="leading-normal my-5">Địa chỉ: Số 1 Trần Quốc Vượng - Cầu Giấy - Hà Nội
                    <br />Hotline : 19003119
                </p>
                </div>
            </div>
            </form>
            </div>
        </div>
    </section>
  )
}

export default Contact