import React from 'react';
import {BrowserRouter as Router , Routes, Route} from 'react-router-dom'
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Register from './pages/Register';
import Header from './components/Header';
import Courses from './pages/Courses';
import About from './pages/About';
import Contact from './pages/Contact';

import './App.css';
import Footer from './components/Footer';
import CourseDetail from './pages/CourseDetail';
import User from './pages/User';
import BuyCourse from './pages/BuyCourse';

function App() {
  return (
    <>
      <Router>
          <div>
            <Header/>
            <Routes>
              <Route path='/' element={<Dashboard/>} />
              <Route path='/login' element={<Login/>} />
              <Route path='/register' element={<Register/>} />
              <Route path='/user-dashboard' element={<User/>} />
              <Route path='/courses' element={<Courses />}/>
              <Route path='/buy-course' element={<BuyCourse />}/>
              <Route exact path='/courses/:id' element={<CourseDetail />}/>
              <Route path='/about' element={<About />}/>
              <Route path='/contact' element={<Contact />}/>
            </Routes>
            <Footer />
          </div>
      </Router>
    </>
  );
}

export default App;
