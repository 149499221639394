import React from 'react'

const Stats = () => {
  return (
    <section className="text-gray-600 body-font">
    <div className="container px-5 py-24 mx-auto">
      <div className="flex flex-wrap -m-4 text-center">
        <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
          <div className="border-2 border-gray-200 px-4 py-6 rounded-lg">
            <svg  fill="#2C5282" className="text-blue-800 w-12 h-12 mb-3 inline-block" id="fi_5638999" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m503.651 94.116c-16.404-8.116-33.739-14.417-51.651-18.8v-28.014c0-6.33-3.973-11.978-9.93-14.117-56.193-19.238-145.823-7.305-186.07 42.967-40.25-50.273-129.876-62.204-186.07-42.967-5.957 2.14-9.93 7.788-9.93 14.117v28.013c-17.911 4.383-35.247 10.684-51.651 18.8-5.114 2.53-8.349 7.741-8.349 13.445v364.959c0 4.796 2.294 9.303 6.171 12.126 3.878 2.824 8.871 3.621 13.436 2.149 73.166-23.608 144.863-23.545 232.469.202 2.574.698 5.277.698 7.85 0 87.608-23.747 159.304-23.81 232.469-.202 4.564 1.473 9.558.673 13.436-2.149 3.877-2.823 6.171-7.331 6.171-12.126v-364.959c-.002-5.705-3.237-10.915-8.351-13.444zm-193.38-23.199c32.205-15.928 78.205-20.77 111.729-12.479v325.305c-56.171-8.392-110.531 14.722-151 45.754v-319.463c7.202-19.784 25.196-32.156 39.271-39.117zm-220.271-12.479c46.716-10.798 129.203-.501 151 51.596v319.462c-40.263-31.266-96.668-54.28-151-45.752zm-60 393.993v-335.339c9.695-4.297 19.728-7.881 30-10.756v295.925c0 4.693 2.196 9.115 5.936 11.951 3.737 2.835 8.589 3.759 13.108 2.494 48.845-13.674 98.167 4.176 136.021 30.905-66.698-12.8-125.735-11.203-185.065 4.82zm452 0c-59.329-16.023-118.366-17.619-185.064-4.821 37.855-26.728 87.178-44.578 136.021-30.905 4.522 1.266 9.371.342 13.108-2.494 3.739-2.836 5.936-7.258 5.936-11.951v-295.924c10.271 2.875 20.304 6.459 30 10.756v335.339z"></path></svg>
            <h2 className="title-font font-medium text-3xl text-gray-900">2</h2>
            <p className="leading-relaxed text-lg">Khoá học</p>
          </div>
        </div>
        <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
          <div className="border-2 border-gray-200 px-4 py-6 rounded-lg">
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="text-blue-800 w-12 h-12 mb-3 inline-block" viewBox="0 0 24 24">
              <path d="M17 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2"></path>
              <circle cx="9" cy="7" r="4"></circle>
              <path d="M23 21v-2a4 4 0 00-3-3.87m-4-12a4 4 0 010 7.75"></path>
            </svg>
            <h2 className="title-font font-medium text-3xl text-gray-900">3000+</h2>
            <p className="leading-relaxed text-lg">Học viên</p>
          </div>
        </div>
        <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
          <div className="border-2 border-gray-200 px-4 py-6 rounded-lg">
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="text-blue-800 w-12 h-12 mb-3 inline-block" viewBox="0 0 24 24">
              <path d="M3 18v-6a9 9 0 0118 0v6"></path>
              <path d="M21 19a2 2 0 01-2 2h-1a2 2 0 01-2-2v-3a2 2 0 012-2h3zM3 19a2 2 0 002 2h1a2 2 0 002-2v-3a2 2 0 00-2-2H3z"></path>
            </svg>
            <h2 className="title-font font-medium text-3xl text-gray-900">100+</h2>
            <p className="leading-relaxed text-lg">Video giảng dạy chuyên sâu</p>
          </div>
        </div>
        <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
          <div className="border-2 border-gray-200 px-4 py-6 rounded-lg">
            <svg fill="#2C5282" className="text-blue-800 w-12 h-12 mb-3 inline-block" viewBox="0 0 24 24">
              <path xmlns="http://www.w3.org/2000/svg" d="m12 1a11 11 0 1 0 11 11 11.013 11.013 0 0 0 -11-11zm0 20a9 9 0 1 1 9-9 9.011 9.011 0 0 1 -9 9z"/>
              <path xmlns="http://www.w3.org/2000/svg" d="m13 11.586v-5.586a1 1 0 0 0 -2 0v6a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414z"/>
            </svg>
            <h2 className="title-font font-medium text-3xl text-gray-900">10000+ giờ</h2>
            <p className="leading-relaxed text-lg">Thời gian sử dụng nền tảng</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Stats