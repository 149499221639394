import React, { useState } from 'react'

function AddCourse() {
    const [inputCount, setInputCount] = useState(1);
    
    const handleAddInput = () => {
        setInputCount( prev => prev + 1 );
     };
    
     const handleDeleteInput = () => {
        setInputCount( prev => prev - 1 );
    };    

  return (
    <>
        <div class="px-5 py-24 mx-auto">

            <div class="flex flex-col text-center w-full mb-4">
                <h1 class="sm:text-2xl text-2xl font-medium title-font mb-0 text-gray-900">Tạo Khoá Học Mới</h1>
            </div>

            <div class="lg:w-2/2 md:w-3/3 mx-auto">
                <div class="flex flex-wrap -m-2">
                <form>

                    <div class="p-2 w-full">
                        <div class="relative">
                            <label for="course_name" class="leading-7 text-base text-gray-800">Tên Khoá:</label>
                            <input type="text" id="course_name" name="course_name" class="w-full bg-gray-100 bg-opacity-50 rounded-sm border-2 border-gray-300 focus:border-blue-400 focus:bg-white focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                        </div>
                    </div>

                    <div class="p-2 w-full">
                        <div class="relative">
                            <label for="category" class="leading-7 text-base text-gray-800">Thứ tự Khoá:</label>
                            <input type="text" id="id" name="id" class="w-full bg-gray-100 bg-opacity-50 rounded-sm border-2 border-gray-300 focus:border-blue-400 focus:bg-white focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                        </div>
                    </div>

                    <div class="p-2 w-full">
                        <div class="relative">
                            <label for="course_details" class="leading-7 text-base text-gray-800">Mô tả tóm tắt:</label>
                            <input type="text" id="course_details" name="course_details" class="w-full bg-gray-100 bg-opacity-50 rounded-sm border-2 border-gray-300 focus:border-blue-400 focus:bg-white focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                        </div>
                    </div>

                    <div class="p-2 w-full">
                        <div class="relative">
                            <label for="course_details" class="leading-7 text-base text-gray-800">Mô tả chi tiết:</label>
                            <input type="text" id="course_details" name="course_details" class="w-full bg-gray-100 bg-opacity-50 rounded-sm border-2 border-gray-300 focus:border-blue-400 focus:bg-white focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                        </div>
                    </div>

                    <div class="p-2 w-full">
                        <div class="relative">
                            <label for="author" class="leading-7 text-base text-gray-800">Người giảng dạy:</label>
                            <input type="text" id="author" name="author" class="w-full bg-gray-100 bg-opacity-50 rounded-sm border-2 border-gray-300 focus:border-blue-400 focus:bg-white focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                        </div>
                    </div>

                    <div class="p-2 w-full">
                        <div class="relative">
                            <label for="price" class="leading-7 text-base text-gray-800">Giá khoá học:</label>
                            <input type="text" id="price" name="price" class="w-full bg-gray-100 bg-opacity-50 rounded-sm border-2 border-gray-300 focus:border-blue-400 focus:bg-white focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                        </div>
                    </div>

                    <div class="p-2 w-full">
                        <div class="relative">
                            <label for="course_img" class="leading-7 text-base text-gray-800">Hình ảnh bìa (URL): </label>
                            <input type="text" id="course_img" name="course_img" class="w-full bg-gray-100 bg-opacity-50 rounded-sm border-2 border-gray-300 focus:border-blue-400 focus:bg-white focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                        </div>
                    </div>

                    <div class="p-2 w-full">
                        <div class="relative">
                            <label for="author_img" class="leading-7 text-base text-gray-800">Hình ảnh tác giả (URL):</label>
                            <input type="text" id="author_img" name="author_img" class="w-full bg-gray-100 bg-opacity-50 rounded-sm border-2 border-gray-300 focus:border-blue-400 focus:bg-white focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                        </div>
                    </div>

                    <div class="p-2 w-full">
                        <div class="relative">
                            <p for="sections" class="leading-7 text-base text-gray-800 mb-4">Nội dung khoá học:</p>
                            <div>
                                {[...Array(inputCount)].map((e, i) => (
                                    <div key={i} class='mb-5'>
                                        <label class="leading-7 text-base text-gray-800" for={`input-${i + 1}-section-name`}>Tên Phần {i + 1}</label>
                                        <input class="w-full bg-gray-100 bg-opacity-50 rounded-sm border-2 border-gray-300 focus:border-blue-400 focus:bg-white focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" id={`input-${i + 1}-section-name`} type="text" />

                                        <label class="leading-7 text-base text-gray-800" for={`input-${i + 1}-url`}>Đường dẫn Video</label>
                                        <input class="w-full bg-gray-100 bg-opacity-50 rounded-sm border-2 border-gray-300 focus:border-blue-400 focus:bg-white focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" id={`input-${i + 1}-url`} type="text" />
                                    </div>
                                ))}
                                <div class='w-full flex justify-center flex-row mt-4 '>
                                    <button type='button' class="flex mx-4 text-white bg-orange-600 border-0 py-2 px-8 focus:outline-none hover:bg-blue-800 rounded-lg text-lg" onClick={handleAddInput}>Thêm Phần</button >
                                    <button type='button' disabled={ inputCount === 1 ? true : false } class="flex mx-4 text-white bg-orange-600 border-0 py-2 px-8 focus:outline-none hover:bg-blue-800 rounded-lg text-lg" onClick={handleDeleteInput}>Xoá Phần</button >
                                </div>       
                            </div>
                        </div>
                    </div>

                    <div class="p-2 w-full mt-3">
                        <input type='submit' value='TẠO KHOÁ HỌC' class="flex mx-auto text-white bg-blue-600 border-0 py-2 px-8 focus:outline-none hover:bg-blue-800 rounded-lg text-lg" />
                    </div>

                </form>
                </div>
            </div>
        </div> 
    </>
  )
}

export default AddCourse