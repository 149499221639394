import React from 'react'

const Features = () => {
  return (
    <section className="text-gray-800 body-font">
        <div className="container px-5 py-24 mx-auto">
            <div className="flex flex-col text-center w-full mb-20">
            <h1 className="sm:text-4xl text-2xl font-bold title-font text-blue-800">CÁC KHOÁ HỌC ĐẾN TỪ CHUYÊN GIA CỦA CHÚNG TÔI</h1>
            </div>
            <div className="flex flex-wrap -m-4">
            <div className="p-4 md:w-1/3">
                <div className="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
                <div className="flex items-center mb-3">
                    <div className="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-green-600 text-white flex-shrink-0">
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5" viewBox="0 0 24 24">
                        <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                    </svg>
                    </div>
                    <h2 className="text-gray-900 text-lg title-font font-medium">Xây dựng nền tảng</h2>
                </div>
                <div className="flex-grow">
                    <p className="leading-relaxed text-base">Xây dựng một nền tảng kiến thức vững chắc về cấu trúc, phân loại & bệnh lý da thẩm mỹ.</p>

                </div>
                </div>
            </div>
            <div className="p-4 md:w-1/3">
                <div className="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
                <div className="flex items-center mb-3">
                    <div className="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-blue-800 text-white flex-shrink-0">
                    <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512"><path clip-rule="evenodd" d="m256 0c-141.2 0-256 114.8-256 256s114.8 256 256 256 256-114.8 256-256-114.8-256-256-256z" fill="#38a169" fill-rule="evenodd"/><path d="m206.7 373.1c-32.7-32.7-65.2-65.7-98-98.4-3.6-3.6-3.6-9.6 0-13.2l37.7-37.7c3.6-3.6 9.6-3.6 13.2 0l53.9 53.9 138.6-138.7c3.7-3.6 9.6-3.6 13.3 0l37.8 37.8c3.7 3.7 3.7 9.6 0 13.2l-183.3 183.1c-3.6 3.7-9.5 3.7-13.2 0z" fill="#fff"/></svg>
                    </div>
                    <h2 className="text-gray-900 text-lg title-font font-medium">Kiến thức chuẩn xác</h2>
                </div>
                <div className="flex-grow">
                    <p className="leading-relaxed text-base">Chúng tôi đào tạo chuẩn xác các kiến thức và phối hợp các phương pháp với nhau để đạt được hiệu quả cao nhất trong trị liệu.</p>
                </div>
                </div>
            </div>
            <div className="p-4 md:w-1/3">
                <div className="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
                <div className="flex items-center mb-3">
                    <div className="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-green-600 text-white flex-shrink-0">
                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-5 h-5" viewBox="0 0 24 24">
                        <circle cx="6" cy="6" r="3"></circle>
                        <circle cx="6" cy="18" r="3"></circle>
                        <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
                    </svg>
                    </div>
                    <h2 className="text-gray-900 text-lg title-font font-medium">Chuyên sâu và thực tiễn</h2>
                </div>
                <div className="flex-grow">
                    <p className="leading-relaxed text-base">Chúng tôi cung cấp các khoá học cao cấp và thực hành để học viên đạt đến trình độ và tay nghề cao.</p>
                </div>
                </div>
            </div>
            </div>
        </div>
    </section>
  )
}

export default Features