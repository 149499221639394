import React, { useState, useEffect } from "react";
import axios from "axios";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

function UserManagement( { userToken, userRole } ) {

    const [users, setUsers] = useState([]);
    const [editingUser, setEditingUser] = useState(null);
    const [searchInput, setSearchInput] = useState(null);

    const [page, setPage] = useState(0);

    const maxPage = Math.ceil(users.length/20);
    const onNextPage = () => setPage((page+1)%maxPage);
    const onPrevPage = () => setPage((page-1)%maxPage);

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {

        try {
          const response = await fetch(`${process.env.REACT_APP_SERVERURL}/api/users/get-all`, {
            method: 'GET',
            headers: 
            { 
                'Authorization' : 'Bearer ' + userToken,
                'Content-Type' : 'application/json',
            }
          })
          
          const userData = await response.json()
          setUsers(userData)
          
        } catch(err) {
          console.error(err)
        }
    };

    //Editing User Information

    const editUser = (user) => {
        setEditingUser(user);
    };

    const saveUser = async (userId, editingUser) => {

      const response = await fetch(`${process.env.REACT_APP_SERVERURL}/api/users/edit`, {
        method: 'PUT',
        headers: {
          'Authorization' : 'Bearer ' + userToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify( editingUser ),
      });
  
      if (!response.ok) {
        throw new Error('Something went wrong!');
      }
  
      const data = await response.json();
      fetchUsers();
      setEditingUser(null);
   };

    const editSubmit = (userId, editingUser) => {

      confirmAlert({
        title: 'Xác nhận Lưu?',
        message: 'Bạn có muốn lưu các thay đổi.',
        buttons: [
          {
            label: 'Xác nhận',
            onClick: () => saveUser(userId, editingUser)
          },
          {
            label: 'Huỷ',
            //onClick: () => alert('Click No')
          }
        ]
      });
    }

    const deleteUser = async (userId) => {
      const response = await fetch(`${process.env.REACT_APP_SERVERURL}/api/users/${userId}`, {
        method: 'DELETE',
        headers: {
          'Authorization' : 'Bearer ' + userToken,
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();
  
      if (!response.ok) {
        throw new Error('Something went wrong!');
      }
  
      fetchUsers();
      setEditingUser(null);

    };

    const deleteSubmit = (userId) => {

      confirmAlert({
        title: 'Xác nhận xoá?',
        message: 'Bạn có muốn xoá người dùng này không.',
        buttons: [
          {
            label: 'Xác nhận',
            onClick: () => deleteUser(userId)
          },
          {
            label: 'Huỷ',
            //onClick: () => alert('Click No')
          }
        ]
      });
    }

  return (
    <>
         <div class="">
              <div className="search-wrapper">
                  <h2 class="text-gray-900 text-lg font-medium title-font mb-5">Tìm kiếm người dùng</h2>
                  <label htmlFor="search-form">
                      <input
                          class='rounded-sm mr-3 text-gray-900 px-2 w-full py-2 mb-12'
                          type="search"
                          name="search-form"
                          id="search-form"
                          placeholder="Nhập ID hoặc Email người dùng"
                          value={searchInput}
                          /* 
                          // set the value of our useState e
                          //  anytime the user types in the search box
                          */
                          onChange={(e) => setSearchInput(e.target.value)}
                      />
                  </label>
              </div>
              <ul>
                {users.filter(function (el) {
                      if (searchInput) {
                        return el._id.toLowerCase().includes(searchInput.toLowerCase()) || el.email.toLowerCase().includes(searchInput.toLowerCase()) || el.number.toLowerCase().includes(searchInput.toLowerCase()) || el.name.toLowerCase().includes(searchInput.toLowerCase())
                      } else {
                        return el._id !== null
                      }
                      
                  }).slice(page*20,20*(page+1)).map((user) => (
                  <li key={user._id}>
                    {editingUser && editingUser._id === user._id ? (
                      <div>
                        <label for="name" className="leading-7 text-base text-gray-700 mr-1">Tên</label>
                        <input class="rounded-sm mr-3 text-gray-900 px-2 mt-2"
                          id='name'
                          name='name'
                          type="text"
                          placeholder="Tên"
                          value={editingUser.name}
                          onChange={(e) => setEditingUser({ ...editingUser, name: e.target.value })}
                        />
                        <label for="email" className="leading-7 text-base text-gray-700 mr-2">Địa chỉ Email</label>
                        <input class="rounded-sm mr-3 text-gray-900 px-2 mt-2"
                          id='email'
                          name='email'
                          type="text"
                          placeholder="Email"
                          value={editingUser.email}
                          onChange={(e) => setEditingUser({ ...editingUser, email: e.target.value })}
                        />
                        <br />
                        <label for="number" className="leading-7 text-base text-gray-700 mr-2">SĐT</label>
                        <input class="rounded-sm mr-3 text-gray-900 px-2 mt-2"
                          id='number'
                          name='number'
                          type="text"
                          placeholder="SĐT"
                          value={editingUser.number}
                          onChange={(e) => setEditingUser({ ...editingUser, number: e.target.value })}
                        />
                        <label for="number" className="leading-7 text-base text-gray-700 mr-2">Mật khẩu (tối đa 20 ký tự)</label>
                        <input class="rounded-sm mr-3 text-gray-900 px-2 mt-2"
                          id='password'
                          name='password'
                          type="text"
                          placeholder="Mật khẩu"
                          maxLength="20"
                          minLength="8"
                          value={editingUser.password}
                          onChange={(e) => setEditingUser({ ...editingUser, password: e.target.value })}
                        />
                        <br />
                        <label for="course" className="leading-7 text-base text-gray-700 mr-2">Khoá học</label>
                        <input class="rounded-sm mr-3 text-gray-900 px-2 mt-2"
                         id='course'
                         name='course'
                         type="text"
                         placeholder="Khoá học"
                         value={editingUser.course}
                         onChange={(e) => setEditingUser({ ...editingUser, course: e.target.value })}
                        />
                      </div>
                      
                    ) : (
                      <div>
                        <span class="mr-5 mt-3 text-gray-900">ID: <span class='font-semibold text-blue-900'>{user._id}</span></span>
                        <span class="mr-5 mt-3 text-gray-900">Tên người dùng: <span class='font-semibold text-blue-900'>{user.name}</span></span>
                        <br />
                        <span class="mr-5 mt-3 text-gray-900">Email: <span class='font-semibold text-blue-900'>{user.email}</span></span>
                        <span class="mr-5 mt-3 text-gray-900">SĐT: <span class='font-semibold text-blue-900'>{user.number}</span></span>
                        <span class="mr-5 mt-3 text-gray-900">Khoá học: <span class='font-semibold text-blue-900'>{user.course}</span></span>
                      </div>

                    )}
                    <div class="mt-3 mb-8">
                      <button onClick={() => editUser(user)} class="inline-flex items-center text-white bg-blue-600 border-0 py-1 px-4 focus:outline-none hover:bg-blue-800 rounded-lg text-base mt-4 md:mt-0 mr-2">Chỉnh sửa</button>
                      <button onClick={() => editSubmit(user._id, editingUser)} class="inline-flex items-center text-white bg-orange-600 border-0 py-1 px-4 focus:outline-none hover:bg-blue-800 rounded-lg text-base mt-4 md:mt-0 mr-12">Lưu</button>
                        { userRole === 'superadmin' ? <button onClick={() => deleteSubmit(user._id)} class="inline-flex items-center text-white bg-red-700 border-0 py-1 px-4 focus:outline-none hover:bg-blue-800 rounded-lg text-base mt-4 md:mt-0 mr-2">Xoá người dùng?</button> : <></>}
                    </div>

                  </li>
                ))}
              </ul>
              <div class='flex w-full justify-center text-gray-900 font-semibold'>
                <button class='mr-5' onClick={onPrevPage} disabled={!page}>&lt; Trang trước</button>
                <p>{page + 1} trên {maxPage}</p>
                <button class='ml-5' onClick={onNextPage} disabled={page==Math.ceil(users.length/20)-1}>Trang tiếp &gt;</button>
              </div>
         </div>
    </>
  )
}

export default UserManagement