import React, { useRef, useState }  from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';

import { Pagination } from 'swiper/modules';
import './ChildVidSlider.css'

function CardSlide( { childvid } ) {
    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '">' + (index + 1) + '</span>';
        },
      };

  return (
    <div className='playlist-section' id="playlist">
      <div className='playlist-section-container'>

            <Swiper
                slidesPerView={3}
                spaceBetween={0}
                centeredSlides={true}
                initialSlide={1}
                loop={true}
                pagination={pagination}
                modules={[Pagination]}
                className="vid-gallery"
                breakpoints={{
                    0: {
                    slidesPerView: 1,
                    },
                    768:{
                    slidesPerView: 3
                    },
                }}
            >

                {
                    childvid.map( (video, i) => (
                        <SwiperSlide key={i} className='video-card'>
                            <div className="cursor-pointer bg-transparent h-auto transition-all ease-in-out duration-300 overflow-hidden text-gray-700">
                                <div className="w-auto flex flex-col">
                                    <video className="h-auto" onContextMenu={e => e.preventDefault()} controls controlsList="nodownload">
                                        <source src={video.child_section_url} type="video/mp4"/>
                                    </video>
                                    <div className='flex justify-center mt-2'>
                                        <h3 className='font-semibold'>{video.child_section_name}</h3>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))
                }
                
            </Swiper>

      </div>
    </div>

  )
}

export default CardSlide