import React, {useState} from 'react'
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom"
import { useForm } from "react-hook-form";
import './BuyCourse.css'


function BuyCourse() {

    const [cookies, setCookie, removeCookie] = useCookies(null);
    const authToken = cookies.AuthToken;

    const location = useLocation()
    const courseName = location.state?.courseName
    const coursePrice = location.state?.coursePrice
    const courseImg = location.state?.courseImg
    const user1 = location.state?.user

    const [user, setUser] = useState({
        Id: user1.Id,
        Name: user1.Name,
        Email: user1.Email,
        Number: user1.Number,
        Role: user1.Role
      });

    const [openModal, setOpenModal] = useState(false);

    const handleOpenModal = () => {
        setOpenModal(!openModal);
    }
    const handleCloseModal = () => {
        setOpenModal(!openModal);
    }

    const {
        register,
        handleSubmit,
        formState: { errors }
      } = useForm();

    const onSubmit = (data, e) => {
        e.preventDefault();
        const action = e.target.action;
        const form = document.getElementById('form-customer');

        const name = data.name
        const tel = data.tel
        const email = data.email
        const message = data.message
        const userid = data.userId
        const coursename = data.courseName
        const courseprice = data.coursePrice

        const data1 = new FormData(form);
        data1.set("id", userid)
        data1.set("name", name)
        data1.set("tel", tel)
        data1.set("email", email)
        data1.set("note", message)
        data1.set("course", coursename)
        data1.set("price", courseprice)
        

        fetch(action, {
            method: 'POST',
            body: data1
        })
    
        setTimeout(setOpenModal(true), 1500);
    };


  return (
    <section class="text-gray-800 body-font relative">
    {authToken ? <div class="px-5 py-12 mx-auto">
        <div class="flex flex-col text-center w-full mb-12 justify-center items-center">
            <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Đặt mua khoá học</h1>
            <p class="lg:w-2/3 mx-auto leading-relaxed text-lg mb-6">Điền vào biểu mẫu đưới đây và tiến hành đăng ký. Chúng tôi sẽ liên hệ với bạn trong thời gian sớm nhất</p>
            <p class="lg:w-2/3 mx-auto leading-relaxed text-lg font-semibold">Bạn đang chọn mua khoá học: <span class='font-bold text-orange-600'>{courseName}</span></p>
            <p class="lg:w-2/3 mx-auto leading-relaxed text-lg font-semibold mb-5">Giá khoá học:<span class='font-bold text-orange-600'> {coursePrice}</span></p>
            <img class="rounded object-cover object-center mb-6" src={courseImg} width='400px' alt="content" />
        </div>
        <div class="lg:w-1/2 md:w-2/3 mx-auto">
        <form class="flex flex-wrap -m-2" onSubmit={handleSubmit(onSubmit)} id='form-customer'
            method='POST'
            action='https://script.google.com/macros/s/AKfycbxFa_G-KwOxj3hSc6H8paVjK4AbM-5BS4ZrFsYPkiCS_jDVd7mlIRuZpTw-zuJJcgnN/exec'>
            <div class="p-2 w-1/2">
                <div class="relative">
                    <label for="name" class="leading-7 text-base text-gray-800 font-semibold">Tên của bạn</label>
                    <input
                        value={user.Name}    
                        onChange={(e) => setUser({ ...user, Name: e.target.value })}    
                        type="text" id="name" name="name" class="w-full bg-gray-100 bg-opacity-50 rounded-sm border-2 border-gray-300 focus:border-blue-400 focus:bg-white focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                        {...register("name", {
                            required: true
                        })}
                        />
                        {errors.name && <p class='text-red-600'>Vui lòng nhập tên.</p>}
                </div>
            </div>
            <div class="p-2 w-1/2">
                <div class="relative">
                    <label for="tel" class="leading-7 text-base text-gray-800 font-semibold">Số điện thoại</label>
                    <input  
                        value={user.Number}
                        onChange={(e) => setUser({ ...user, Number: e.target.value })}
                        type="tel" id="tel" name="tel" maxLength="10" class="w-full bg-gray-100 bg-opacity-50 rounded-sm border-2 border-gray-300 focus:border-blue-400 focus:bg-white focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                        {...register("tel", {
                            required: true,
                            pattern: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
                        })}
                        />
                        {errors.tel && <p class='text-red-600'>Vui lòng kiểm tra lại SĐT.</p>}
                </div>
            </div>
            <div class="p-2 w-full">
                <div class="relative">
                    <label for="email" class="leading-7 text-base text-gray-800 font-semibold">Địa chỉ Email</label>
                    <input 
                        value={user.Email}
                        onChange={(e) => setUser({ ...user, Email: e.target.value })}
                        type="email" id="email" name="email" class="w-full bg-gray-100 bg-opacity-50 rounded-sm border-2 border-gray-300 focus:border-blue-400 focus:bg-white focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" 
                        {...register("email", {
                            required: true,
                            pattern: /^\S+@\S+$/i
                        })}
                        />
                        {errors.tel && <p class='text-red-600'>Vui lòng kiểm tra Email.</p>}
                </div>
            </div>
            <div class="p-2 w-full">
                <div class="relative">
                    <label for="message" class="leading-7 text-base text-gray-800 font-semibold">Lời nhắn (tuỳ chọn)</label>
                    <textarea id="message" name="message" class="w-full bg-gray-100 bg-opacity-50 rounded-sm border-2 border-gray-300 focus:border-blue-400 focus:bg-white focus:ring-2 focus:ring-blue-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                    {...register("message")}
                    />
                </div>
            </div>
            <div class="p-2 w-full">
                <div class="relative">
                    <input type="hidden" id="user-id" name="user-id" value={user.Id} {...register("userId")}/>
                    <input type="hidden" id="course-name" name="course-name" value={courseName} {...register("courseName")}/>
                    <input type="hidden" id="course-price" name="course-price" value={coursePrice} {...register("coursePrice")}/>
                </div>
            </div>
            <div class="p-2 w-full flex flex-col items-center justify-center">
                <button type='submit' class="flex mx-auto text-white bg-blue-600 border-0 py-2 px-8 focus:outline-none hover:bg-blue-800 rounded-lg text-lg" >ĐĂNG KÝ</button>
                {openModal && <p class='text-blue-800 mt-5 font-semibold text-xl'>Đã gửi yêu cầu đăng ký!</p>}
            </div>
        </form>
        </div>
    </div> : <p class='text-blue-800 mt-5 font-semibold text-2xl text-center mt-20'>Vui lòng đăng nhập để tiến hành đăng ký!</p>}
    </section>
  )
}

export default BuyCourse