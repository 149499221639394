import React, { useState } from 'react';
import ChildVidSlider from './ChildVidSlider'

const Accordion = ({ items }) => {
  const [openIndexes, setOpenIndexes] = useState([]);

  const handleItemClick = (index) => {
    setOpenIndexes((prevState) => {
      if (prevState.includes(index)) {
        return prevState.filter((i) => i !== index);
      } else {
        return [...prevState, index];
      }
    });
  };


  return (
    <div className="w-full mx-auto">
      {items.map((item, index) => (
        <div key={index} className="border-b border-gray-200 mb-5 flex flex-col items-center justify-center">
          <button
            type="button"
            onClick={() => handleItemClick(index)}
            className="flex items-center justify-between w-full px-4 py-4 text-base font-medium text-left text-gray-900 bg-gray-200 focus:outline-none focus:shadow-outline-blue mb-5"
          >
            <div className='flex'>
              <img src='/img/play-button.svg' width='24px' class='mr-4'></img>
              <span>{item.section_name}</span>
            </div>
            <span className="ml-6 flex-shrink-0">
                {openIndexes.includes(index) ?
                  <>
                    <img src='/img/expand-arrow.svg' width='24px'/>
                  </>
                :
                  <>
                    <img src='/img/expand-button.svg' width='24px'/>
                  </>
                }
            </span>
          </button>
          {openIndexes.includes(index) && (
            <div className="px-4 pb-4 w-80 flex justify-center flex-col">
              <video className="max-h-screen w-11/12 mx-auto" onContextMenu={e => e.preventDefault()} controls controlsList="nodownload">
                <source src={item.video_url} type="video/mp4"/>
              </video>
              {
              item.child_section ?
              <div class="child-video">
                  <div>
                    <h4 className="text-2xl font-semibold mt-16 text-center">BÀI HỌC THỰC HÀNH:</h4>
                  </div>
                    <div>
                      <ChildVidSlider childvid={item.child_section} />
                    </div>
              </div> : null
            }
            </div>

          )}
        </div>
      ))}
    </div>
  );
};

export default Accordion;